import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes } from "../lib/helpers";
import BlogPostPreviewGrid from "../components/blog/blog-post-preview-grid";
import ParallaxContainer from "../components/parallax-container";
import Pagination from "../components/Pagination";
import Blogroll from "../components/blog/Blogroll";
import Archive from "../components/blog/Archive";

const BlogIndex = (props) => {
  const { data, errors } = props;
  const currentPage = props.pageContext && props.pageContext.currentPage;
  const numPages = props.pageContext && props.pageContext.numPages;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? "/blog" : `/blog/${(currentPage - 1).toString()}`;
  const nextPage = `/blog/${(currentPage + 1).toString()}`;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const sanityBlogSettings = (data || {}).sanityBlogSettings;
  const postNodes = data && data.posts && mapEdgesToNodes(data.posts);
  const archivePosts =
    data && data.archivePosts && mapEdgesToNodes(data.archivePosts);

  return (
    <Layout>
      <SEO
        title={sanityBlogSettings.title}
        description={sanityBlogSettings.description}
        keywords={sanityBlogSettings.keywords}
      />
      <main id="main" className="site-main" role="main">
        <div id="aboutUs">
          <div style={{ overflow: "hidden" }} className="fullWidth">
            <ParallaxContainer images={sanityBlogSettings.parallaxImages} />
            <div id="homeBanner">
              <span>{sanityBlogSettings.title}</span>
            </div>
          </div>
          <div className="wp1220">
            <p className="center">{sanityBlogSettings.description}</p>

            <div className="index-wrapper">
              <div className="index-main-content">
                {postNodes && postNodes.length > 0 && (
                  <BlogPostPreviewGrid nodes={postNodes} />
                )}
              </div>
              <div className="index-sidebar">
                <Archive title="Blog Archive" archiveData={archivePosts} route="blog" />
                <Blogroll data={sanityBlogSettings.blogRoll} />
              </div>
            </div>
            {currentPage && (
              <Pagination
                isFirst={isFirst}
                prevPage={prevPage}
                currentPage={currentPage}
                numPages={numPages}
                nextPage={nextPage}
                isLast={isLast}
              />
            )}
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default BlogIndex;

export const blogIndexGROQ = `
*[ _id == $id ] {
  _id,
  _type,
  title,
  description,
  keywords,
  parallaxImages[] {
    alt,
    asset->
  },
  parallaxColour,
	blogRoll[] {
    icon {
    	alt,
    	asset->
  	},
    label,
		description,
		target
  },
  publishedAt,
  author->{
    title,
    mainImage {
      asset->
    }
  },
	categories[]->,
	mainImage {
    alt,
    asset->
  },
	slug,
  body,
  "posts": *[_type=='post' && defined(slug.current)] | order(publishedAt desc) {
    id,
    publishedAt,
    mainImage {asset->},
		title,
		body,
		slug
  }
}`;

export const query = graphql`
  query BlogPageQuery($skip: Int!, $limit: Int!) {
    sanityBlogSettings {
      title
      description
      keywords
      parallaxImages {
        asset {
          url
          _id
        }
        alt
      }
      parallaxColour {
        hex
      }
      blogRoll {
        icon {
          alt
          asset {
            _id
            url
          }
        }
        label
        target
        description
      }
    }
    archivePosts: allSanityPost(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          publishedAt
          title
          slug {
            current
          }
        }
      }
    }
    posts: allSanityPost(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawBody
          slug {
            current
          }
        }
      }
    }
  }
`;
