import React, { useState, useEffect } from "react";

export default function ParallaxContainer(props) {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offset]);
  return (
    <div style={props.style} id="parallax-container">
      {props.images.map((image, i) => (
        <div
          key={i}
          className="parallax-layer"
          style={{
            backgroundImage: `url(${image.asset.url})`,
            transform: `translateY(${offset * (i + 1) * 0.16}px)`,
          }}
        />
      ))}
    </div>
  );
}
