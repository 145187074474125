import React from "react";
import { buildImageObj } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";

export default function Blogroll(props) {
  const { data } = props;
  return (
    <div>
      <hr />
      <h1 className="index-sidebar-header">Blogroll</h1>

      {data.map((item, index) => {
        return (
          <div key={index} className="blogroll-row">
            {item.icon && item.icon.asset ? (
              <img
                src={imageUrlFor(buildImageObj(item.icon))
                  .width(40)
                  .height(40)
                  .url()}
                alt={item.icon.alt}
              />
            ) : (
              <div style={{ height: 40, width: 40 }} />
            )}
            <div style={{ height: 40, width: 10 }} />
            <a href={item.target} className="blogroll-link">
              {item.description}
            </a>
          </div>
        );
      })}
    </div>
  );
}
/*
              <img className="blogroll-image" src={item.icon.asset.url} />
*/
